import React from 'react';
import { Link } from 'gatsby';

const styles = require('./logo.module.scss');

interface ILogo {
  type: 'colorful' | 'white';
  className?: string;
}

export const Logo: React.FunctionComponent<ILogo> = ({ type, className }) => {
  return (
    <Link className={className} to="/">
      <span className={styles.logo}>
        {type === 'colorful' && (
          <svg viewBox="0 0 120 25" version="1.1">
            <path
              d="M15.3152119,4.69960494 L15.3152119,4.69926001 C15.3582073,4.55887184 15.3611309,4.45884096 15.3465125,4.38743961 C15.3434169,4.37657419 15.3394613,4.36639863 15.3358497,4.35708541 C15.3334419,4.3498418 15.3305183,4.34277065 15.3269067,4.33656184 C15.3263907,4.33483717 15.3258748,4.33294003 15.3251868,4.33156029 C15.2598339,4.2085913 15.0728902,4.18444591 15.0391818,4.18082411 C14.991027,4.17789217 14.9402925,4.1787545 14.8883541,4.18306618 C14.8878382,4.18306618 14.8866343,4.18306618 14.8854305,4.18358358 C14.8825068,4.18358358 14.880099,4.18358358 14.8776913,4.18410098 C14.8725319,4.18444591 14.8666845,4.18496332 14.8606651,4.18565318 C14.8169819,4.18944746 14.7748464,4.19565627 14.7333989,4.20221002 C14.4962366,4.24515431 14.2026644,4.3681233 14.0822774,4.42141562 C7.36279303,7.59877576 3.94758683,13.2099907 2.3880597,16.7164179 C4.91584199,15.8042398 7.36279303,15.0479718 8.04676296,15.2535525 C8.88138859,15.5044921 14.2788521,14.9174143 19.1044776,14.3194711 C13.5962581,9.59508166 14.9526752,5.83874969 15.3152119,4.69960494"
              fill="#36B6C1"
            />
            <path
              d="M11.9402985,15.3288659 C5.34018232,10.4779707 6.07116086,5.01182975 6.73873553,2.78757418 C6.86624195,2.35114629 7.26203582,1.5147911 7.26203582,1.5147911 L7.26081316,1.51601948 C7.30500374,1.42617184 7.35303698,1.32140809 7.38779557,1.21295918 C7.39251156,1.20014887 7.39670356,1.18628566 7.40159421,1.17154503 C7.40386487,1.1622444 7.40561154,1.15382118 7.40875553,1.14487151 C7.41102619,1.13521991 7.41399552,1.12539283 7.41644085,1.11556574 C7.41766351,1.11293348 7.41766351,1.11082768 7.41766351,1.10819543 C7.47390607,0.856727321 7.38657291,0.731607462 7.30814773,0.673346882 C7.30814773,0.672469464 7.30744907,0.671767529 7.30692507,0.671241078 C7.30395574,0.669661725 7.30081175,0.668257856 7.29854108,0.666678503 C7.25190518,0.634214023 7.21050926,0.624562421 7.21050926,0.624562421 C6.836374,0.497863209 6.21560991,0.836897666 5.86749993,1.05800709 C5.85090663,1.0697645 5.79641074,1.10608962 5.75501482,1.13399153 L5.75553882,1.13434249 C5.73999352,1.14487151 5.72444822,1.15540053 5.70837892,1.16592955 L5.70768025,1.16628052 C5.66401367,1.19576178 5.62034709,1.2245411 5.58331783,1.25191655 C-1.74323558,6.89073344 -0.588691209,16.8204771 1.91200648,20.3801634 C4.43907878,23.9761749 9.75627086,26.3034394 9.6507724,22.6014358 C9.54492461,18.8985548 11.9402985,15.3288659 11.9402985,15.3288659"
              fill="#BDD341"
            />
            <path
              d="M9.62012968,22.5976712 C9.51286524,18.8871846 11.9402985,15.310509 11.9402985,15.310509 C9.32170089,13.4081416 7.84725732,11.4115264 7.05675733,9.55223881 C3.3704714,13.4408471 1.73406915,17.7024031 1.19402985,19.3811073 C1.37740603,19.7486041 1.57264147,20.0830437 1.77778914,20.3719419 C4.33868344,23.9751687 9.72704012,26.3071029 9.62012968,22.5976712"
              fill="#128A74"
            />
            <path
              d="M28.2620024,21.1870264 C26.9398386,19.5700725 19.1734357,12.863991 25.7046317,1.848448 C25.7046317,1.848448 25.9396597,1.45693586 25.9558987,1.102012 C25.9558987,1.08769485 25.9564225,1.07373121 25.9558987,1.05959082 C25.9552002,1.04651095 25.9541526,1.03343109 25.9534541,1.01893719 C25.9527557,1.00903891 25.951708,0.998963881 25.9504857,0.989065606 C25.9497872,0.986237528 25.9497872,0.983232694 25.9497872,0.98058137 C25.9455966,0.948765486 25.9396597,0.916949601 25.9325006,0.885133716 C25.9061342,0.799054073 25.8570682,0.722165685 25.7769211,0.662776034 C25.5219873,0.50617118 25.0550742,0.664543583 24.7994419,0.772717591 C22.5485155,1.77208988 16.9727977,5.9253001 11.9402985,15.2537175 C11.9402985,15.2537175 20.0981821,23.2161728 30.1233689,24.4683047 C30.1233689,24.4683047 31.064878,24.5833721 31.0444484,24.004323 C31.0247172,23.4243901 29.5841663,22.8050409 28.2620024,21.1870264"
              fill="#1B729A"
            />
            <path
              d="M30.5375554,23.3264599 C30.5047279,23.2930235 30.4591537,23.253572 30.3982134,23.2082825 C30.3982134,23.2082825 30.397515,23.2075748 30.3969911,23.2068672 C30.2641099,23.0959431 30.1155135,22.977058 29.9552179,22.8454352 C29.2895896,22.2635703 28.5116845,21.4518963 28.1584405,21.0763114 C27.7884336,20.6495987 27.1049947,19.9561022 26.3576471,19.0032521 C19.3530092,15.465889 16.5195489,12.1471896 15.4978828,9.55223881 C14.3145241,11.1909782 13.1128311,13.0835869 11.9402985,15.2592553 C11.9402985,15.2592553 19.9538557,23.0872744 29.856561,24.4477306 C30.1258157,24.479221 30.5478576,24.5071732 30.7745065,24.4137634 C30.9307859,24.3474213 31.0517934,24.2287131 31.0444596,24.0178335 C31.0373004,23.8074847 30.8440028,23.5921823 30.5375554,23.3264599"
              fill="#1F5D7C"
            />
            <path
              d="M49.4576364,16.9494643 C49.410625,17.1542289 49.2716955,17.3134328 49.0396202,17.3134328 L44.1665649,17.3134328 C43.9343142,17.3134328 43.771879,17.1542289 43.7255692,16.9494643 L42.1243723,8.557042 L42.101042,8.557042 L40.4307312,16.9494643 C40.4072255,17.1542289 40.2447903,17.3134328 40.012715,17.3134328 L35.1394843,17.3134328 C34.9074089,17.3134328 34.7684795,17.1542289 34.7221697,16.9494643 L32.238806,5.94152348 L32.238806,5.80518573 C32.238806,5.55503261 32.3782617,5.37313433 32.6566468,5.37313433 L35.3252498,5.37313433 C35.6271406,5.37313433 35.7894004,5.44121724 35.8592159,5.75979713 L37.4369071,15.1301376 L38.0634929,15.1301376 L39.5720701,6.35105251 C39.6183799,6.12359369 39.7806397,5.98725594 40.012715,5.98725594 L44.3984648,5.98725594 C44.6305401,5.98725594 44.7927999,6.12359369 44.8391097,6.35105251 L46.3941722,15.1301376 L47.0205825,15.1301376 L48.4593442,5.75979713 C48.505654,5.44121724 48.691595,5.37313433 48.9698047,5.37313433 L51.4994782,5.37313433 C51.7778633,5.37313433 51.9402985,5.55503261 51.9402985,5.80518573 L51.9402985,5.94152348 L49.4576364,16.9494643 Z"
              fill="#333738"
            />
            <path
              d="M60.2874447,11.9263664 L57.774901,12.3130243 C56.8821163,12.449077 56.1073872,12.6086938 56.1073872,13.9738645 C56.1073872,15.5669363 57.4223318,15.7029889 58.3619837,15.7029889 C59.2309798,15.7029889 59.9824528,15.5437162 60.2874447,15.4534157 L60.2874447,11.9263664 Z M61.6029219,17.5917302 C60.7339258,17.7969272 59.6300612,17.9104478 58.2678943,17.9104478 C55.543383,17.9104478 52.5373134,17.5917302 52.5373134,14.0196167 C52.5373134,11.1069544 54.463307,10.7202965 56.177688,10.4702073 L60.2643662,9.83277218 L60.2643662,9.21855717 C60.2643662,8.60417016 60.1937103,8.21751227 59.7001844,7.98995515 C59.371759,7.83033837 58.8081098,7.76239804 58.0328482,7.76239804 C56.5066461,7.76239804 54.7219642,8.23987238 54.0878368,8.37661305 C54.040437,8.39966116 53.9703137,8.42202127 53.9229139,8.42202127 C53.7823123,8.42202127 53.6880453,8.35373693 53.6649668,8.21751227 L53.3125751,6.64748857 C53.2887864,6.60173635 53.2887864,6.57903224 53.2887864,6.53362401 C53.2887864,6.32877101 53.4538868,6.23761056 53.5706998,6.19237434 C54.0878368,5.98734934 56.2950335,5.37313433 58.8081098,5.37313433 C60.4516575,5.37313433 61.6029219,5.60069144 62.3774735,6.03310156 C63.551994,6.64748857 63.880597,7.6939417 63.880597,8.96795194 L63.880597,15.2256866 C63.880597,16.9549831 63.2228584,17.2503085 61.6029219,17.5917302 Z"
              fill="#333738"
            />
            <g transform="translate(65.074627, 0.000000)">
              <path
                d="M11.9233326,17.0811558 C11.9233326,17.1954245 11.8053553,17.3100394 11.6875562,17.3100394 L7.98624192,17.3100394 C7.89214524,17.3100394 7.75046558,17.2870125 7.6563689,17.1498901 L4.49682335,12.477514 L3.74262419,12.477514 L3.74262419,16.9665409 C3.74262419,17.1723976 3.57759857,17.3100394 3.36552462,17.3100394 L0.489160169,17.3100394 C0.253383827,17.3100394 0.112238806,17.1723976 0.112238806,16.9665409 L0.112238806,1.07159761 C0.112238806,0.750779701 0.206335487,0.567776716 0.536030296,0.521896119 L3.20085542,0.0866364179 L3.31865449,0.0866364179 C3.55371798,0.0866364179 3.74262419,0.201078209 3.74262419,0.498869254 L3.74262419,10.1412394 L4.49682335,10.1412394 L7.79751392,5.44618269 C7.91531299,5.30871403 8.01012252,5.28603343 8.1280998,5.28603343 L8.17514814,5.28603343 L11.4993629,5.35442149 C11.6403297,5.35442149 11.7114368,5.46903642 11.7114368,5.58347821 C11.7114368,5.60633194 11.6875562,5.65238567 11.6643885,5.6752394 L7.49134328,11.1265469 L11.8762843,16.9895678 C11.8999866,17.0354484 11.9233326,17.058129 11.9233326,17.0811558"
                fill="#333738"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M85.5139703,10.8792253 C85.5139703,10.2648383 85.4681029,9.76448785 85.3779084,9.26396539 C85.1062981,8.08059959 84.495132,7.6937697 83.3628232,7.6937697 C81.9366549,7.6937697 81.4158544,8.14905593 81.2121895,9.17297695 C81.0988901,9.74195574 81.0988901,10.3334666 81.0759564,11.015966 L85.5139703,11.015966 L85.5139703,10.8792253 Z M88.9324613,12.5407534 C88.9324613,12.8360789 88.819162,13.1320923 88.3892406,13.1320923 L81.0759564,13.1320923 C81.0759564,14.087729 81.3927496,14.6336597 82.0497831,15.0664138 C82.6159375,15.4073195 83.3855857,15.5669363 84.495132,15.5669363 C85.8759463,15.5669363 87.0305043,15.2028105 87.6195924,15.0435377 C87.6418415,15.0203176 87.6644329,15.0203176 87.6875377,15.0203176 C87.8458488,15.0203176 87.9813972,15.1341822 88.0493426,15.3847874 L88.3438867,16.6359215 C88.3663069,16.7040338 88.3663069,16.7497861 88.3663069,16.7726622 C88.3663069,16.9771712 88.2530076,17.0910357 88.1176303,17.1596641 C87.3928225,17.4551615 85.8307635,17.9104478 83.9058727,17.9104478 C79.4916482,17.9104478 77.6119403,16.2721397 77.6119403,11.6989813 C77.6119403,7.26170358 78.9254938,5.37313433 83.3176404,5.37313433 C85.5593243,5.37313433 86.9630723,5.89618889 87.8230862,6.92045391 C88.6606798,7.94420293 88.9552239,9.44611429 88.9552239,11.4483761 C88.9552239,11.7213414 88.9324613,12.2216919 88.9324613,12.5407534 Z"
              fill="#333738"
            />
            <path
              d="M93.9846855,17.9104478 C91.9221114,17.9104478 90.073661,17.2731846 89.7412767,17.1596641 C89.6706787,17.1142559 89.5522388,17.0685036 89.5522388,16.9097469 L89.5522388,16.8178984 L89.7892977,15.8168535 C89.8127707,15.7029889 89.9079168,15.6123445 90.0496505,15.6123445 C90.0969547,15.6123445 90.1211444,15.6123445 90.1682695,15.6347046 C90.4053285,15.6808008 92.064741,16.2492636 93.9375604,16.2492636 C95.7863692,16.2492636 96.9005283,15.7029889 96.9005283,14.3837425 C96.9005283,12.6315699 95.5252998,12.6768061 93.7949308,12.4040128 C92.562511,12.2216919 89.6706787,11.9941348 89.6706787,8.96795194 C89.6706787,5.94194112 92.443892,5.37313433 94.5535911,5.37313433 C96.5679649,5.37313433 98.109296,5.78249633 98.3933008,5.85095267 C98.5830555,5.89618889 98.6545494,6.01005345 98.6545494,6.12391801 L98.6545494,6.16949823 L98.4646156,7.28440769 C98.4407843,7.42097636 98.3223444,7.44350847 98.2277358,7.44350847 L98.1802524,7.44350847 C97.8487641,7.42097636 96.1651618,7.03414647 94.8144813,7.03414647 C93.2262044,7.03414647 91.851155,7.44350847 91.851155,8.80867916 C91.851155,10.2880584 93.3921277,10.4241111 94.8144813,10.606432 C96.9005283,10.8792253 99.1044776,11.1298305 99.1044776,14.1785455 C99.1044776,17.137132 96.7578987,17.9104478 93.9846855,17.9104478"
              fill="#333738"
            />
            <path
              d="M106.18847,18.9731359 C105.506045,21.1111485 104.776119,22.0895522 102.163652,22.0895522 C101.245864,22.0895522 100.822639,22.044165 100.399059,21.9757404 C100.163696,21.952531 100.116553,21.8165413 100.116553,21.7027294 L100.116553,20.6106857 C100.116553,20.4968739 100.234145,20.3608842 100.399059,20.3608842 L100.422719,20.3608842 C100.681387,20.3608842 101.316669,20.3834059 101.787571,20.3834059 C103.011348,20.3834059 103.669935,20.1336043 104.023067,18.905227 L104.517452,17.3130644 L103.411446,17.3130644 C103.175906,17.3130644 103.011348,17.176387 102.940543,16.9491072 L99.1279605,5.7597852 C99.1279605,5.73691967 99.1044776,5.69136054 99.1044776,5.66866694 C99.1044776,5.50946787 99.2460863,5.37313433 99.4343049,5.37313433 L101.05818,5.37313433 C101.293542,5.37313433 101.363634,5.50946787 101.434261,5.69136054 L104.564418,15.8345418 L105.176039,15.8345418 L108.023691,5.69136054 C108.070656,5.50946787 108.188426,5.37313433 108.447627,5.37313433 L110.142306,5.37313433 C110.306864,5.37313433 110.447761,5.50946787 110.447761,5.66866694 L110.447761,5.7597852 L106.18847,18.9731359 Z"
              fill="#333738"
            />
            <path
              d="M114.880387,17.9104478 C112.817634,17.9104478 110.969004,17.2731846 110.636799,17.1596641 C110.566201,17.1142559 110.447761,17.0685036 110.447761,16.9097469 L110.447761,16.8178984 L110.684641,15.8168535 C110.708114,15.7029889 110.803081,15.6123445 110.945173,15.6123445 C110.992477,15.6123445 111.016308,15.6123445 111.063613,15.6347046 C111.300672,15.6808008 112.960263,16.2492636 114.832904,16.2492636 C116.681533,16.2492636 117.795692,15.7029889 117.795692,14.3837425 C117.795692,12.6315699 116.420822,12.6768061 114.690453,12.4040128 C113.457675,12.2216919 110.566201,11.9941348 110.566201,8.96795194 C110.566201,5.94194112 113.339056,5.37313433 115.448934,5.37313433 C117.463487,5.37313433 119.00446,5.78249633 119.288823,5.85095267 C119.478578,5.89618889 119.550072,6.01005345 119.550072,6.12391801 L119.550072,6.16949823 L119.360138,7.28440769 C119.335948,7.42097636 119.217508,7.44350847 119.1229,7.44350847 L119.075775,7.44350847 C118.743928,7.42097636 117.060505,7.03414647 115.710004,7.03414647 C114.121727,7.03414647 112.746498,7.44350847 112.746498,8.80867916 C112.746498,10.2880584 114.287471,10.4241111 115.710004,10.606432 C117.795692,10.8792253 120,11.1298305 120,14.1785455 C120,17.137132 117.653421,17.9104478 114.880387,17.9104478"
              fill="#333738"
            />
          </svg>
        )}

        {type === 'white' && (
          <svg viewBox="0 0 120 25" version="1.1">
            <g id="Desktop-Copy-4" transform="translate(-648.000000, -4008.000000)" fill="#FFFFFF">
              <g id="Group-26-Copy" transform="translate(648.000000, 4008.000000)">
                <path
                  d="M15.3152119,4.69960494 L15.3152119,4.69926001 C15.3582073,4.55887184 15.3611309,4.45884096 15.3465125,4.38743961 C15.3434169,4.37657419 15.3394613,4.36639863 15.3358497,4.35708541 C15.3334419,4.3498418 15.3305183,4.34277065 15.3269067,4.33656184 C15.3263907,4.33483717 15.3258748,4.33294003 15.3251868,4.33156029 C15.2598339,4.2085913 15.0728902,4.18444591 15.0391818,4.18082411 C14.991027,4.17789217 14.9402925,4.1787545 14.8883541,4.18306618 C14.8878382,4.18306618 14.8866343,4.18306618 14.8854305,4.18358358 C14.8825068,4.18358358 14.880099,4.18358358 14.8776913,4.18410098 C14.8725319,4.18444591 14.8666845,4.18496332 14.8606651,4.18565318 C14.8169819,4.18944746 14.7748464,4.19565627 14.7333989,4.20221002 C14.4962366,4.24515431 14.2026644,4.3681233 14.0822774,4.42141562 C7.36279303,7.59877576 3.94758683,13.2099907 2.3880597,16.7164179 C4.91584199,15.8042398 7.36279303,15.0479718 8.04676296,15.2535525 C8.88138859,15.5044921 14.2788521,14.9174143 19.1044776,14.3194711 C13.5962581,9.59508166 14.9526752,5.83874969 15.3152119,4.69960494"
                  opacity="0.8"
                />
                <path
                  d="M11.9402985,15.3288659 C5.34018232,10.4779707 6.07116086,5.01182975 6.73873553,2.78757418 C6.86624195,2.35114629 7.26203582,1.5147911 7.26203582,1.5147911 L7.26081316,1.51601948 C7.30500374,1.42617184 7.35303698,1.32140809 7.38779557,1.21295918 C7.39251156,1.20014887 7.39670356,1.18628566 7.40159421,1.17154503 C7.40386487,1.1622444 7.40561154,1.15382118 7.40875553,1.14487151 C7.41102619,1.13521991 7.41399552,1.12539283 7.41644085,1.11556574 C7.41766351,1.11293348 7.41766351,1.11082768 7.41766351,1.10819543 C7.47390607,0.856727321 7.38657291,0.731607462 7.30814773,0.673346882 C7.30814773,0.672469464 7.30744907,0.671767529 7.30692507,0.671241078 C7.30395574,0.669661725 7.30081175,0.668257856 7.29854108,0.666678503 C7.25190518,0.634214023 7.21050926,0.624562421 7.21050926,0.624562421 C6.836374,0.497863209 6.21560991,0.836897666 5.86749993,1.05800709 C5.85090663,1.0697645 5.79641074,1.10608962 5.75501482,1.13399153 L5.75553882,1.13434249 C5.73999352,1.14487151 5.72444822,1.15540053 5.70837892,1.16592955 L5.70768025,1.16628052 C5.66401367,1.19576178 5.62034709,1.2245411 5.58331783,1.25191655 C-1.74323558,6.89073344 -0.588691209,16.8204771 1.91200648,20.3801634 C4.43907878,23.9761749 9.75627086,26.3034394 9.6507724,22.6014358 C9.54492461,18.8985548 11.9402985,15.3288659 11.9402985,15.3288659"
                  opacity="0.8"
                />
                <path d="M9.62012968,22.5976712 C9.51286524,18.8871846 11.9402985,15.310509 11.9402985,15.310509 C9.32170089,13.4081416 7.84725732,11.4115264 7.05675733,9.55223881 C3.3704714,13.4408471 1.73406915,17.7024031 1.19402985,19.3811073 C1.37740603,19.7486041 1.57264147,20.0830437 1.77778914,20.3719419 C4.33868344,23.9751687 9.72704012,26.3071029 9.62012968,22.5976712" />
                <path
                  d="M28.2620024,21.1870264 C26.9398386,19.5700725 19.1734357,12.863991 25.7046317,1.848448 C25.7046317,1.848448 25.9396597,1.45693586 25.9558987,1.102012 C25.9558987,1.08769485 25.9564225,1.07373121 25.9558987,1.05959082 C25.9552002,1.04651095 25.9541526,1.03343109 25.9534541,1.01893719 C25.9527557,1.00903891 25.951708,0.998963881 25.9504857,0.989065606 C25.9497872,0.986237528 25.9497872,0.983232694 25.9497872,0.98058137 C25.9455966,0.948765486 25.9396597,0.916949601 25.9325006,0.885133716 C25.9061342,0.799054073 25.8570682,0.722165685 25.7769211,0.662776034 C25.5219873,0.50617118 25.0550742,0.664543583 24.7994419,0.772717591 C22.5485155,1.77208988 16.9727977,5.9253001 11.9402985,15.2537175 C11.9402985,15.2537175 20.0981821,23.2161728 30.1233689,24.4683047 C30.1233689,24.4683047 31.064878,24.5833721 31.0444484,24.004323 C31.0247172,23.4243901 29.5841663,22.8050409 28.2620024,21.1870264"
                  opacity="0.8"
                />
                <path d="M30.5375554,23.3264599 C30.5047279,23.2930235 30.4591537,23.253572 30.3982134,23.2082825 C30.3982134,23.2082825 30.397515,23.2075748 30.3969911,23.2068672 C30.2641099,23.0959431 30.1155135,22.977058 29.9552179,22.8454352 C29.2895896,22.2635703 28.5116845,21.4518963 28.1584405,21.0763114 C27.7884336,20.6495987 27.1049947,19.9561022 26.3576471,19.0032521 C19.3530092,15.465889 16.5195489,12.1471896 15.4978828,9.55223881 C14.3145241,11.1909782 13.1128311,13.0835869 11.9402985,15.2592553 C11.9402985,15.2592553 19.9538557,23.0872744 29.856561,24.4477306 C30.1258157,24.479221 30.5478576,24.5071732 30.7745065,24.4137634 C30.9307859,24.3474213 31.0517934,24.2287131 31.0444596,24.0178335 C31.0373004,23.8074847 30.8440028,23.5921823 30.5375554,23.3264599" />
                <path d="M49.4576364,16.9494643 C49.410625,17.1542289 49.2716955,17.3134328 49.0396202,17.3134328 L44.1665649,17.3134328 C43.9343142,17.3134328 43.771879,17.1542289 43.7255692,16.9494643 L42.1243723,8.557042 L42.101042,8.557042 L40.4307312,16.9494643 C40.4072255,17.1542289 40.2447903,17.3134328 40.012715,17.3134328 L35.1394843,17.3134328 C34.9074089,17.3134328 34.7684795,17.1542289 34.7221697,16.9494643 L32.238806,5.94152348 L32.238806,5.80518573 C32.238806,5.55503261 32.3782617,5.37313433 32.6566468,5.37313433 L35.3252498,5.37313433 C35.6271406,5.37313433 35.7894004,5.44121724 35.8592159,5.75979713 L37.4369071,15.1301376 L38.0634929,15.1301376 L39.5720701,6.35105251 C39.6183799,6.12359369 39.7806397,5.98725594 40.012715,5.98725594 L44.3984648,5.98725594 C44.6305401,5.98725594 44.7927999,6.12359369 44.8391097,6.35105251 L46.3941722,15.1301376 L47.0205825,15.1301376 L48.4593442,5.75979713 C48.505654,5.44121724 48.691595,5.37313433 48.9698047,5.37313433 L51.4994782,5.37313433 C51.7778633,5.37313433 51.9402985,5.55503261 51.9402985,5.80518573 L51.9402985,5.94152348 L49.4576364,16.9494643 Z" />
                <path d="M60.2874447,11.9263664 L57.774901,12.3130243 C56.8821163,12.449077 56.1073872,12.6086938 56.1073872,13.9738645 C56.1073872,15.5669363 57.4223318,15.7029889 58.3619837,15.7029889 C59.2309798,15.7029889 59.9824528,15.5437162 60.2874447,15.4534157 L60.2874447,11.9263664 Z M61.6029219,17.5917302 C60.7339258,17.7969272 59.6300612,17.9104478 58.2678943,17.9104478 C55.543383,17.9104478 52.5373134,17.5917302 52.5373134,14.0196167 C52.5373134,11.1069544 54.463307,10.7202965 56.177688,10.4702073 L60.2643662,9.83277218 L60.2643662,9.21855717 C60.2643662,8.60417016 60.1937103,8.21751227 59.7001844,7.98995515 C59.371759,7.83033837 58.8081098,7.76239804 58.0328482,7.76239804 C56.5066461,7.76239804 54.7219642,8.23987238 54.0878368,8.37661305 C54.040437,8.39966116 53.9703137,8.42202127 53.9229139,8.42202127 C53.7823123,8.42202127 53.6880453,8.35373693 53.6649668,8.21751227 L53.3125751,6.64748857 C53.2887864,6.60173635 53.2887864,6.57903224 53.2887864,6.53362401 C53.2887864,6.32877101 53.4538868,6.23761056 53.5706998,6.19237434 C54.0878368,5.98734934 56.2950335,5.37313433 58.8081098,5.37313433 C60.4516575,5.37313433 61.6029219,5.60069144 62.3774735,6.03310156 C63.551994,6.64748857 63.880597,7.6939417 63.880597,8.96795194 L63.880597,15.2256866 C63.880597,16.9549831 63.2228584,17.2503085 61.6029219,17.5917302 Z" />
                <path d="M76.9979595,17.0811558 C76.9979595,17.1954245 76.8799822,17.3100394 76.7621831,17.3100394 L73.0608688,17.3100394 C72.9667721,17.3100394 72.8250924,17.2870125 72.7309958,17.1498901 L69.5714502,12.477514 L68.8172511,12.477514 L68.8172511,16.9665409 C68.8172511,17.1723976 68.6522254,17.3100394 68.4401515,17.3100394 L65.563787,17.3100394 C65.3280107,17.3100394 65.1868657,17.1723976 65.1868657,16.9665409 L65.1868657,1.07159761 C65.1868657,0.750779701 65.2809624,0.567776716 65.6106572,0.521896119 L68.2754823,0.0866364179 L68.3932814,0.0866364179 C68.6283448,0.0866364179 68.8172511,0.201078209 68.8172511,0.498869254 L68.8172511,10.1412394 L69.5714502,10.1412394 L72.8721408,5.44618269 C72.9899399,5.30871403 73.0847494,5.28603343 73.2027267,5.28603343 L73.249775,5.28603343 L76.5739898,5.35442149 C76.7149566,5.35442149 76.7860637,5.46903642 76.7860637,5.58347821 C76.7860637,5.60633194 76.7621831,5.65238567 76.7390154,5.6752394 L72.5659701,11.1265469 L76.9509111,16.9895678 C76.9746135,17.0354484 76.9979595,17.058129 76.9979595,17.0811558" />
                <path d="M85.5139703,10.8792253 C85.5139703,10.2648383 85.4681029,9.76448785 85.3779084,9.26396539 C85.1062981,8.08059959 84.495132,7.6937697 83.3628232,7.6937697 C81.9366549,7.6937697 81.4158544,8.14905593 81.2121895,9.17297695 C81.0988901,9.74195574 81.0988901,10.3334666 81.0759564,11.015966 L85.5139703,11.015966 L85.5139703,10.8792253 Z M88.9324613,12.5407534 C88.9324613,12.8360789 88.819162,13.1320923 88.3892406,13.1320923 L81.0759564,13.1320923 C81.0759564,14.087729 81.3927496,14.6336597 82.0497831,15.0664138 C82.6159375,15.4073195 83.3855857,15.5669363 84.495132,15.5669363 C85.8759463,15.5669363 87.0305043,15.2028105 87.6195924,15.0435377 C87.6418415,15.0203176 87.6644329,15.0203176 87.6875377,15.0203176 C87.8458488,15.0203176 87.9813972,15.1341822 88.0493426,15.3847874 L88.3438867,16.6359215 C88.3663069,16.7040338 88.3663069,16.7497861 88.3663069,16.7726622 C88.3663069,16.9771712 88.2530076,17.0910357 88.1176303,17.1596641 C87.3928225,17.4551615 85.8307635,17.9104478 83.9058727,17.9104478 C79.4916482,17.9104478 77.6119403,16.2721397 77.6119403,11.6989813 C77.6119403,7.26170358 78.9254938,5.37313433 83.3176404,5.37313433 C85.5593243,5.37313433 86.9630723,5.89618889 87.8230862,6.92045391 C88.6606798,7.94420293 88.9552239,9.44611429 88.9552239,11.4483761 C88.9552239,11.7213414 88.9324613,12.2216919 88.9324613,12.5407534 Z" />
                <path d="M93.9846855,17.9104478 C91.9221114,17.9104478 90.073661,17.2731846 89.7412767,17.1596641 C89.6706787,17.1142559 89.5522388,17.0685036 89.5522388,16.9097469 L89.5522388,16.8178984 L89.7892977,15.8168535 C89.8127707,15.7029889 89.9079168,15.6123445 90.0496505,15.6123445 C90.0969547,15.6123445 90.1211444,15.6123445 90.1682695,15.6347046 C90.4053285,15.6808008 92.064741,16.2492636 93.9375604,16.2492636 C95.7863692,16.2492636 96.9005283,15.7029889 96.9005283,14.3837425 C96.9005283,12.6315699 95.5252998,12.6768061 93.7949308,12.4040128 C92.562511,12.2216919 89.6706787,11.9941348 89.6706787,8.96795194 C89.6706787,5.94194112 92.443892,5.37313433 94.5535911,5.37313433 C96.5679649,5.37313433 98.109296,5.78249633 98.3933008,5.85095267 C98.5830555,5.89618889 98.6545494,6.01005345 98.6545494,6.12391801 L98.6545494,6.16949823 L98.4646156,7.28440769 C98.4407843,7.42097636 98.3223444,7.44350847 98.2277358,7.44350847 L98.1802524,7.44350847 C97.8487641,7.42097636 96.1651618,7.03414647 94.8144813,7.03414647 C93.2262044,7.03414647 91.851155,7.44350847 91.851155,8.80867916 C91.851155,10.2880584 93.3921277,10.4241111 94.8144813,10.606432 C96.9005283,10.8792253 99.1044776,11.1298305 99.1044776,14.1785455 C99.1044776,17.137132 96.7578987,17.9104478 93.9846855,17.9104478" />
                <path d="M106.18847,18.9731359 C105.506045,21.1111485 104.776119,22.0895522 102.163652,22.0895522 C101.245864,22.0895522 100.822639,22.044165 100.399059,21.9757404 C100.163696,21.952531 100.116553,21.8165413 100.116553,21.7027294 L100.116553,20.6106857 C100.116553,20.4968739 100.234145,20.3608842 100.399059,20.3608842 L100.422719,20.3608842 C100.681387,20.3608842 101.316669,20.3834059 101.787571,20.3834059 C103.011348,20.3834059 103.669935,20.1336043 104.023067,18.905227 L104.517452,17.3130644 L103.411446,17.3130644 C103.175906,17.3130644 103.011348,17.176387 102.940543,16.9491072 L99.1279605,5.7597852 C99.1279605,5.73691967 99.1044776,5.69136054 99.1044776,5.66866694 C99.1044776,5.50946787 99.2460863,5.37313433 99.4343049,5.37313433 L101.05818,5.37313433 C101.293542,5.37313433 101.363634,5.50946787 101.434261,5.69136054 L104.564418,15.8345418 L105.176039,15.8345418 L108.023691,5.69136054 C108.070656,5.50946787 108.188426,5.37313433 108.447627,5.37313433 L110.142306,5.37313433 C110.306864,5.37313433 110.447761,5.50946787 110.447761,5.66866694 L110.447761,5.7597852 L106.18847,18.9731359 Z" />
                <path d="M114.880387,17.9104478 C112.817634,17.9104478 110.969004,17.2731846 110.636799,17.1596641 C110.566201,17.1142559 110.447761,17.0685036 110.447761,16.9097469 L110.447761,16.8178984 L110.684641,15.8168535 C110.708114,15.7029889 110.803081,15.6123445 110.945173,15.6123445 C110.992477,15.6123445 111.016308,15.6123445 111.063613,15.6347046 C111.300672,15.6808008 112.960263,16.2492636 114.832904,16.2492636 C116.681533,16.2492636 117.795692,15.7029889 117.795692,14.3837425 C117.795692,12.6315699 116.420822,12.6768061 114.690453,12.4040128 C113.457675,12.2216919 110.566201,11.9941348 110.566201,8.96795194 C110.566201,5.94194112 113.339056,5.37313433 115.448934,5.37313433 C117.463487,5.37313433 119.00446,5.78249633 119.288823,5.85095267 C119.478578,5.89618889 119.550072,6.01005345 119.550072,6.12391801 L119.550072,6.16949823 L119.360138,7.28440769 C119.335948,7.42097636 119.217508,7.44350847 119.1229,7.44350847 L119.075775,7.44350847 C118.743928,7.42097636 117.060505,7.03414647 115.710004,7.03414647 C114.121727,7.03414647 112.746498,7.44350847 112.746498,8.80867916 C112.746498,10.2880584 114.287471,10.4241111 115.710004,10.606432 C117.795692,10.8792253 120,11.1298305 120,14.1785455 C120,17.137132 117.653421,17.9104478 114.880387,17.9104478" />
              </g>
            </g>
          </svg>
        )}
      </span>
    </Link>
  );
};
